import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {SalWrapper} from "react-sal";
import ProductBannerNoLine from "../product_banners_no_line/product_banners_no_line";
import Banner from "../../imgs/bg-busquedas.jpg";
import EmpleosLayout from "../empleos/EmpleosLayout";
import './busqueda.css';


const EmpleosDetail = ({data}) => {
    let text = `¿Compartís nuestro compromismo por <br/> la excelencia y la innovación? <br/><strong> Sumate a la comunidad Balanz. </strong>`;

    return  <EmpleosLayout pageData={{ title: "Detalle del empleo", description: "Explorá nuestras oportunidades" }}>
        <div className="content-detalle-empleo">
    <SalWrapper>
        <ProductBannerNoLine
        bannerContainerClassName="bannerReporte"
        bannerTrasparecy="bannerMask-noblue"
        productTitle="<strong>Oportunidades</strong> <br> búsquedas abiertas"
        productTitleBlueClassName="productTitleEmpleos"
        bannerImage={Banner}
        bannerImageClassName="ReportBannerImage"
        textContainerClassName="textContainer"
        bannerText={text}
        bannerTextClassName="bannerTextInversiones"
        bannerImageMobile={Banner}
        mobile={false}
      />
        <section className="fullwidth pb-0 seccion-empleo">
            <Container>
                <Row className="pb-5 paso-1">
                    <p className="volanta"
                       data-sal="fade"
                       data-sal-delay="100"
                       data-sal-duration="200"
                    >BÚSQUEDA {data.id_search}</p>
                    <h3
                        data-sal="fade"
                        data-sal-delay="200"
                        data-sal-duration="200"
                    >{data.position_name}</h3>
                </Row>
            </Container>

            <div className="bg-lt-grey empleo-information-section"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
                <Container>
                    <Row className="py-4 mb-5">
                        <Col sm={12} md={4} className='card-information'>
                            <h4 className="mb-0"><strong>Sector</strong></h4>
                            <p className="base-text mb-0">
                                {data.sector}
                            </p>
                        </Col>
                        <Col sm={12} md={4} className='card-information'>
                            <h4 className="mb-0"><strong>Nombre del puesto</strong></h4>
                            <p className="base-text mb-0">
                                {data.position_name}
                            </p>
                        </Col>
                        <Col sm={12} md={4} className='card-information'>
                            <h4 className="mb-0"><strong>Seniority</strong></h4>
                            <p className="base-text mb-0">
                                {data.seniority_name}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                
                    <h4 className="mt-5 primary-color"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="200"
                    >Descripción de las tareas a realizar:</h4>
                    <p className="base-text"
                    data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="200"
                    >
                        {data.description_task}
                    </p>

                    <Button target="_blank" href={process.env.GATSBY_RRHH_SEARCH_URL + 'aplicar/' + data.id_search + '?origen=Sitio corporativo Balanz'}
                            type="button"
                            className='my-5' variant="primary">Postular</Button>
                
            <div className="d-flex justify-content-center py-5 bottom-shadow">
                <div>
                    <h6>Búsqueda laboral equitativa Ley 6471-21. El empleador sólo podrá solicitarle la información estrictamente necesaria para el desempeño en el trabajo que se le ofrece.</h6> 
                </div>
            </div>
            </Container>

        </section>
    </SalWrapper>
    </div>
    </EmpleosLayout> 
}

export default EmpleosDetail;