import React from "react";
import {Layout} from "../Layout";
import ProductBannerNoLine from "../product_banners_no_line/product_banners_no_line";
import Banner from "../../imgs/bg-oportunidades.jpg";
import './busqueda.css';
import { useLocation } from '@reach/router';

const text = `¿Compartís nuestro compromismo por <br/> la excelencia y la innovación? <br/><strong> Sumate a la comunidad Balanz. </strong>`;
const imgUrl = process.env.GATSBY_BASE_URL + 'static/bg_empleos-4a3922eb4a1040eacdb4c1435cfea801.jpg'

const EmpleosLayout = ({pageData, children}) => {
    const location = useLocation(); // obtener la ruta actual
    const isDetailPage = location.pathname.startsWith('/trabaja-con-nosotros/') && location.pathname.split('/').length > 2;
    const page = (
        <>
         {!isDetailPage && (
            <ProductBannerNoLine
                bannerContainerClassName="bannerReporte"
                bannerTrasparecy="bannerMask-noblue"
                productTitle="<span class=title-bold>Oportunidades</span> <br/> busquedas abiertas"
                productTitleBlueClassName="productTitleEmpleos"
                bannerImage={Banner}
                bannerImageClassName="ReportBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerTextInversiones"
                bannerImageMobile={Banner}
                mobile={false}
            />
        )}
            {children}

        </>
    )
    return (
        <main>
            <Layout title={pageData.title} description={pageData.description} img={imgUrl} childrem={page}
                    category={"trabaja_con_nosotros"}></Layout>
        </main>
    )
}

export default EmpleosLayout;