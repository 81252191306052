import React, {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../loading/loading";
import EmpleosDetail from "./EmpleosDetail";
import Empleos404 from "./Empleos404";

const EmpleosDynamicDetail = ({slug}) => {
    const [data, setData] = useState(null);
    const [show404, setShow404] = useState(false);

    useEffect(() => {
        if (typeof slug === 'string') {
            const search_id = parseInt(slug.split('-')[0]);
            if (!isNaN(search_id)) {
                axios
                    .get(process.env.GATSBY_RRHH_API_URL.concat("/v1/positions/corporate/", search_id),
                        {id: search_id})
                    .then((res) => {
                        const {data} = res;
                        data.id_search = data.id;
                        setData(data);
                    })
                    .catch((err) => {
                        setShow404(true);
                    })
            } else {
                setShow404(true);
            }
        } else {
            setShow404(true);
        }
    }, []);

    if (show404) return <Empleos404/>

    if (data) return <EmpleosDetail data={data}/>

    return <Loading customClass="loading-searchresults"/>
}

export default EmpleosDynamicDetail;